import React from 'react'
import { Spin, Tabs, message,Modal} from "antd";
// import { withApollo } from "react-apollo";
import { API, graphqlOperation } from 'aws-amplify'
import tableOrderCreated from '../../subscription/tableOrderCreated'
import tableOrderUpdated from '../../subscription/tableOrderUpdated'
import App from '../../../src/components/kitchenRoleApp/kitchenRoleMain'
import updateTableOrderItemStatus from '../../mutations/updateTableOrderItemStatus'
import updateTableOrderItemStatusInBulk from '../../mutations/updateTableOrderItemStatusInBulk'
import getDiningTableBySite from '../../queries/getDiningTableBySite';
import tableOrderUpdatedV2 from '../../subscription/tableOrderUpdatedV2'
import getSiteOrderByItemStatus from '../../queries/getSiteOrderByItemStatus'
import Services from './ServicesbyStatus'
import ItemCount from './ItemCount';
import ServeItems from './serveItems';
import { calculateTime } from '../../utils/calculateTime';
import CancelKotNotify from './CancelKotNotify';
import moment from 'moment'
// import kitchenAudio from '../../audio/kitchen.aac'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { AndroidPostMsg } from '../../utils/AndroidPostMsg';
const { TabPane } = Tabs;
const stations = ['Kitchen', 'Bar']
class Orders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            kitchenStatus: 'NEW',
            createDataSpin: false,
            tabs:"1",
            loading: false,
            serviceId: '',
            subOrderId:'',
            acceptAllLoading:false,
            siteId: this.props.location && this.props.location.state &&  this.props.location.state.siteId ? this.props.location.state.siteId : localStorage.getItem('siteId'),
            station: this.props.location &&this.props.location.state && this.props.location.state.station ? this.props.location.state.station : '',
            trackerConfig: this.props.location &&this.props.location.state && this.props.location.state.trackerConfig ? this.props.location.state.trackerConfig : null,
            mainId:'',
            kotId:null,
            updateflag:false,
            tableData:null,
            platform:'mobile',
            clearIntervalFlag: undefined,
            cancelNotification: null,
            showNotificationModal: false
            //sort: false,
        }
    }




    componentDidMount = () => {
       if (!localStorage.hasOwnProperty('SortItem')) {
        localStorage.setItem('SortItem', true);
      }
         
      if (window.screen.width >= 700) {
        //   1280
        this.setState({
            platform: 'desktop'
        })
    } else {
        this.setState({
            platform: 'mobile'
        })
    }
    
        this.getSiteOrder()
        this.tableList()
        this.tableOrderCreatedSubscription()
        this.tableOrderUpdatedV2SubscriptionCall()

    }

    tableOrderCreatedSubscription = () =>{  
        let that = this;
        let retryAttempts = 0;
        this.subscription = this.props.client.subscribe({
            query: tableOrderCreated,
            variables:{
                siteId: this.state.siteId
            }
        }).subscribe({
            next: ({data}) => {  
                retryAttempts = 0;
                 if(that.state.kitchenStatus == 'NEW' && (data.tableOrderCreated.orderFrom != 'online-menu'
                || (data.tableOrderCreated.orderFrom == 'online-menu' && data.tableOrderCreated.orderStatus != 'NEW'))){
                  this.createOnUpdate(data.tableOrderCreated,this.state.kitchenStatus)
              }
                
            },
            error: (err) => {
                retryAttempts++;
                console.log('tabletableOrderCreated error', err);
                const maxRetryAttempts = 5;

                if (retryAttempts <= maxRetryAttempts) {
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
                  setTimeout(this.tableOrderCreatedSubscription, delay);

                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
            }
        });
    }

    tableOrderUpdatedV2SubscriptionCall = () =>{
        let that = this
        let retryAttempts = 0; 
        this.subscriptionV2 = this.props.client.subscribe({
            query: tableOrderUpdatedV2,
            variables:{
                siteId: this.state.siteId
            }
        }).subscribe({
            next: ({data}) => {
              
                retryAttempts = 0;
                let item = data.tableOrderUpdatedV2
                if (item.tableOrderStatus == "CLOSED") {
                    let FilterDataSOurce = this.state.dataSource.filter((order) => order.orderId != item.orderId)
                    if (FilterDataSOurce) {
                        this.setState({ dataSource: FilterDataSOurce })
                    } else {
                        this.setState({ dataSource: [] })
                    }
                } else {
                    let orders = [];
                    orders = item.orderList.map((x) => {
                        return x.services
                    })
                    let services = orders[0]

                    let newOrder = services.find((x) => x.station == `${that.state.station}`)

                    if (newOrder && that.state.updateflag == false) {
                        if (that.state.kitchenStatus != 'NEW' && (data.tableOrderUpdatedV2.orderFrom != 'online-menu'
                            || (data.tableOrderUpdatedV2.orderFrom == 'online-menu' && data.tableOrderUpdatedV2.orderStatus != 'NEW'))) {
                            message.warning('New order received')
                        }

                        if (that.state.kitchenStatus == 'NEW' && (data.tableOrderUpdatedV2.orderFrom != 'online-menu'
                            || (data.tableOrderUpdatedV2.orderFrom == 'online-menu' && data.tableOrderUpdatedV2.orderStatus != 'NEW'))) {
                            this.createOnUpdate(data.tableOrderUpdatedV2, this.state.kitchenStatus)
                        }
                    }
                    that.setState({
                        updateflag: false
                    })
                }

        // }

            },
            error: (err) => {
                retryAttempts++;
                console.log('table updated v2 error', err);
                const maxRetryAttempts = 5;

                if (retryAttempts <= maxRetryAttempts) {
                  // Calculate the exponential backoff delay (adjust as needed)
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
                  setTimeout(this.tableOrderUpdatedV2SubscriptionCall, delay);

                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
            }
        });
    }

    tableList = () => {
        this.props.client.query({
            query: getDiningTableBySite,
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
                if (data.getDiningTableBySite != null) {
                   
                    this.setState({
                        tableData:data.getDiningTableBySite.items
                    })
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }


    componentWillUnmount() {
        if(this.subscription){

            this.subscription.unsubscribe()
        }
        if(this.subscriptionOnItems){

            this.subscriptionOnItems.unsubscribe()
        }
        if(this.subscriptionV2){

            this.subscriptionV2.unsubscribe()
        }

        if(this.state.clearIntervalFlag){
            clearInterval(this.state.clearIntervalFlag)
        }
      }

    getOrderItemsOnSubscribe =(list)=>{
        let that = this;
        list.map((obj)=>{
        

        this.subscriptionOnItems = this.props.client.subscribe({
                query: tableOrderUpdated,
                variables:{
                    id: obj.id
                },
                // context: this
            }).subscribe({
                next : ({data})=>{
                     this.updateOrderItems(data.tableOrderUpdated)
                },
                error: (err) => { console.error('err', err); },
            });
        })

    }

 updateOrderItems=(newOrder)=>{
        let preArr = JSON.parse(JSON.stringify(this.state.dataSource))
        let index = preArr && preArr.length> 0  ? preArr.findIndex(obj=> obj.id == newOrder.id) : -1
        
        if(index < 0){
         preArr.unshift(newOrder)
        }else{
            preArr[index] = newOrder
        }
       
        this.createData(preArr)
    }

    enterLoading = () => {
        this.setState({
            loading: true
        })
    }

    loadingSpin = () => {
        this.setState({
            createDataSpin: !this.state.createDataSpin
        })
    }

   loadingAcceptAll=()=>{
    this.setState({
        acceptAllLoading:true
    })
   }
    getSiteOrder = () => {
        this.loadingSpin()
        this.props.client.query({
            query: getSiteOrderByItemStatus,
            fetchPolicy: 'network-only',
            variables: {
                station: this.state.station,
                // station:'kitchen',
                status: ['IN_PROCESS','PREPARED','CANCELLED','COMPLETED','SHIFTED'],
                skip: 0,
                limit: 1000
                // tableNo:'1'

            }
        })
            .then(({ data }) => {
                
                if (data.getSiteOrderByItemStatus != null) {
                    
                    let dataCopy = JSON.parse(JSON.stringify(data.getSiteOrderByItemStatus))
                    this.createData(dataCopy)
                    
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: query error: ${JSON.stringify(err, null, 2)}`,err)
            })
    }
  
    filterBYKOtID = (orders) => {
        //  console.log("orders dd ::", orders)

        let response = [];
        let tmpKot = {};
        let newSubOrder = null;

        orders.forEach(order => {
            order.orderList.forEach(subOrder => {

                subOrder.services.forEach(service => {

                    if (service.kotId) {

                        if (!tmpKot[service.kotId]) {
                            order.timestamp = subOrder.createdAt
                            tmpKot[service.kotId] = Object.assign({}, order);

                            delete tmpKot[service.kotId].kotNo;

                            tmpKot[service.kotId].orderList = []

                            tmpKot[service.kotId].kotId = service.kotId

                            newSubOrder = {

                                "subOrderId": subOrder.subOrderId,

                                "createdAt": subOrder.createdAt,

                                "services": [Object.assign({}, service)]

                            }

                            tmpKot[service.kotId].orderList.push(newSubOrder);

                        } else {

                            let index = tmpKot[service.kotId].orderList.findIndex(obj => obj.subOrderId == subOrder.subOrderId);

                            if (index == -1) {

                                newSubOrder = {
                                    "subOrderId": subOrder.subOrderId,
                                    "createdAt": subOrder.createdAt,
                                    "services": [Object.assign({}, service)]
                                }

                                tmpKot[service.kotId].orderList.push(newSubOrder);

                            } else {
                                tmpKot[service.kotId].orderList[index].services.push(Object.assign({}, service))
                            }
                        }
                    }
                });
            });


            Object.keys(tmpKot).forEach(kot => {
                response.push(Object.assign({}, tmpKot[kot]))
            });
            tmpKot = {}
        });

        return response

    }

    createData = (data) => {
    
        let finalData = this.filterBYKOtID(data)

        let flag = localStorage.getItem('SortItem');
        flag = flag === 'true';
       
      if(flag ) {
          finalData.sort((a, b) => a.kotId - b.kotId);
      } else {
          finalData.sort((a, b) => b.kotId - a.kotId); 
      }
      
        let arr = []
        finalData.map((val, i) => {
        if(val.orderStatus!=='DISPATCHED'){
            let obj = {}
            let count = 0
            let totalCount = 0
           let newCount=0
           let inProcessCount = 0
            obj['id'] = val.id
            obj['orderId'] = val.orderId
            obj['kotId'] = val.kotId
            obj['tokenId'] = val.tokenId
            obj['name'] = val.name
            obj['tableNo'] = val.tableNo
            obj['updatedAt'] = val.updatedAt
            obj['timestamp'] = val.timestamp
            obj['orderFrom']=val.orderFrom
            obj['orderSource'] = val.orderSource
            obj['subOrderFrom']=val.subOrderFrom
            obj['newOrderFlag']=val.newOrderFlag
            let arr2 = []
            val.orderList.map((k) => {
                let obj2 = {}
                obj2['subOrderId'] = k.subOrderId
                let arr3 = []
                k.services.map((s) => {
                    let obj3 = {...s}
                    if (s.station==this.state.station ) {
                        count++
                        totalCount = totalCount + 1
                        arr3.push(obj3)

                    }
                    if(s.station==this.state.station && s.status=='NEW'){
                        // newCount = newCount + 1
                    }
                    if(s.station==this.state.station && s.status=='IN_PROCESS'){
                        inProcessCount = inProcessCount + 1
                    }
                })
                if (arr3.length > 0) {
                    obj2['services'] = arr3
                    arr2.push(obj2)
                }

            })
            if (arr2.length > 0) {
                obj['orderList'] = arr2
                obj['totalItems'] = count
                obj['newCount'] = newCount
                obj['inProcessCount'] = inProcessCount
                obj['totalCount'] = totalCount
                obj['tableOrderStatus'] = val.tableOrderStatus
                arr.push(obj)
            }
        }
        })


        let copy = arr && arr.length > 0 && arr.filter((item)=>{
             if( item.inProcessCount > 0 || item.newCount > 0){
                return item
             }
           
        })

         let TimeData = []
         let objDummy
         for(let i=0;i<copy.length;i++){
            objDummy = {
                 kotId: copy[i].kotId,
                 timestamp: copy[i].timestamp,
                 orderId: copy[i].orderId
             }

             TimeData.push(objDummy)
         }

         if(this.state.clearIntervalFlag){
            clearInterval(this.state.clearIntervalFlag)
        }

        let colorTimeObject = {};

        this.state.trackerConfig && this.state.trackerConfig.forEach(item => {
            colorTimeObject[item.color] = item.time;
        });

        let response
       if(this.state.trackerConfig){
        response =  calculateTime(TimeData,colorTimeObject,false )
       }
           
        if(copy){

            this.setState({
                createDataSpin: false,
                dataSource: copy,
                clearIntervalFlag: response
            })

            this.tempCalCulationOrderComp(TimeData,colorTimeObject,false)
        }else{
            this.setState({
                createDataSpin: false,
                dataSource: [],
                clearIntervalFlag: response
            })
        }
        
    }

    tempCalCulationOrderComp = (data,colorTimeObject,isNewKot) =>{ 
        let id, time, adjustedHours, orderTimeInMinutes, delayLimitInMinutes, now, currentMinutes, timeDifference, color, ele;

        for (let i = 0; i < data.length; i++) {
          if(isNewKot){
          id = `NewKotOrder${ data[i].kotId}${ data[i].orderId}`
          }else{
          id = `InProcessKotOrder${ data[i].kotId}${ data[i].orderId}`
          }
          time = moment.unix(data[i].timestamp).format('hh:mm a');
          const [timePart, period] = time.split(' ');
          const [hours, minutes] = timePart.split(':');
          adjustedHours = period === 'pm' && parseInt(hours, 10) !== 12
              ? parseInt(hours, 10) + 12
              : period === 'am' && parseInt(hours, 10) === 12
                  ? 0
                  : parseInt(hours, 10);

          orderTimeInMinutes = adjustedHours * 60 + parseInt(minutes, 10);

          delayLimitInMinutes = 5; // Set your delay limit
          now = new Date();
          currentMinutes = now.getHours() * 60 + now.getMinutes();
          timeDifference = currentMinutes - orderTimeInMinutes;
           
          if(colorTimeObject.green){
              color = 'rgb(0 250 0 / 10%)'
          }
        
          if ( colorTimeObject.orange && timeDifference > colorTimeObject.green) {
              // console.log(`Order is delayed by ${timeDifference} minutes, exceeding the limit of ${delayLimitInMinutes} minutes. kot id ${kotID}`);
              color = 'rgb(246 204 166 / 30%)'
          }
          if ( colorTimeObject.red && timeDifference > colorTimeObject.orange) {
              color = 'rgb(248 164 154 / 20%)'
          }

          ele = document.getElementById(id)
          if (ele) {
              ele.style.backgroundColor = color
          }
      }
    }

    findMissingItems(arr, updated) {
        const missingItems = [];
    
        for (const updatedItem of updated) {
            let flag = false;
            const found = arr.find(
                (arrItem) =>{
                    if(arrItem.kotId === updatedItem.kotId && arrItem.serviceName === updatedItem.serviceName && arrItem.status == "IN_PROCESS" && arrItem.qty == updatedItem.qty){
                                        if(arrItem.uId == updatedItem.uId){
                                            return true
                                        }
                    }else{
                       if(arrItem.kotId === updatedItem.kotId && arrItem.serviceName === updatedItem.serviceName ){
                        if(arrItem.status == "CANCELLED" && arrItem.qty == updatedItem.qty && arrItem.uId == updatedItem.uId){
                                         flag = true
                                      
                                          return false
                         }else{
                                 return true
                         }
                       }
                   }
                
                }
            );
           
            if (found) {
                    
                    if(flag){
                        let extraValidation =  arr.find((item)=> item.uId == updatedItem.uId)
                       if(!extraValidation){
                      missingItems.push(updatedItem);
                       }
                    }else{
                        missingItems.push(updatedItem);
                    }
               
            }
        }
    
        return missingItems;
    }

    cancleItemNotification = (updatedData, previousData, updatedList ) =>{
        
         previousData = JSON.parse(JSON.stringify(previousData))
         updatedList = JSON.parse(JSON.stringify(updatedList))


           let canceledItem = []
           updatedData.orderList.map((suborder)=>{
                suborder.services.map((item)=>{
                          if(item.status == "CANCELLED" && item.kotId){
                            canceledItem.push(item) 
                          }
                })
          })


          let convertUpdatedData = []
          convertUpdatedData.push(updatedData)

          let finalData = this.filterBYKOtID(convertUpdatedData)

          let foundPreviousList = [];

          finalData.map((order)=>{
            let find = previousData.find((prev)=>prev.kotId == order.kotId && prev.orderId == order.orderId)
            if(find){
                foundPreviousList.push(find)
            }
          })

          let previousCancleItemList = []

          foundPreviousList && foundPreviousList.length > 0 && foundPreviousList.map((prevKot)=>{
            // prevKot
            prevKot.orderList.map((subOrder) => {
                subOrder.services.map((item) => {
                    if ( (item.status == "IN_PROCESS" || item.status == "CANCELLED") && item.kotId) {
                        previousCancleItemList.push(item)
                    }
                })
            })
          }) 

          let updatedcanceledItem = canceledItem.filter(updatedItem => {
            return !previousCancleItemList.some(arrItem => arrItem.uId === updatedItem.uId && arrItem.status == "CANCELLED");
        });
       
        const currentlyCancelledItem = this.findMissingItems(previousCancleItemList, updatedcanceledItem);

        let KotIDForExactPrevOrder ;
        let foundPreviousOrder;

        if(currentlyCancelledItem && currentlyCancelledItem.length > 0 ){
            KotIDForExactPrevOrder = currentlyCancelledItem[0].kotId
        }

        if(KotIDForExactPrevOrder){
            foundPreviousOrder = foundPreviousList.find((order)=> order.kotId == KotIDForExactPrevOrder)
        }

         let obj = {}

         if(foundPreviousOrder){
            obj.KotOrder = foundPreviousOrder
         }
          
          let currentUpdatedOrderList;

        if (Object.keys(obj).length > 0) {
            updatedList.map((order) => {
                if (order.kotId == obj.KotOrder.kotId && order.orderId == obj.KotOrder.orderId) {
                    currentUpdatedOrderList = order
                }
            })
        }
          
        let finalDataToReferForCancelNotification = {}
           if(currentlyCancelledItem.length > 0  && currentUpdatedOrderList){               
            finalDataToReferForCancelNotification.order = obj.KotOrder
            finalDataToReferForCancelNotification.currentlyCanceledItem = currentlyCancelledItem,
            finalDataToReferForCancelNotification.updatedList = currentUpdatedOrderList
           }

        if (Object.keys(finalDataToReferForCancelNotification).length > 0) {
        this.setState({cancelNotification:finalDataToReferForCancelNotification, showNotificationModal: true  })
        this.playAudio()
        }


    }   

    createOnUpdate=(data,serviceStatus)=>{
      
    // console.log("called createOnUpdate:::",data)

      let copyForCancleItemNotification = JSON.parse(JSON.stringify(this.state.dataSource)) 
    
       let updatedData = []
       updatedData.push(data)

       let finalData = this.filterBYKOtID(updatedData)
       let finalStateData = this.state.dataSource
       if(finalStateData.length == 0){
        finalStateData = [...finalData, ...finalStateData]
       }else{
            for(let i=0; i<finalStateData.length;i++){
                let index = -1
                let temp = -1
                for(let j=0; j<finalStateData.length;j++){
                   index = finalData.findIndex(item => item.kotId == finalStateData[j].kotId && item.orderId == finalStateData[j].orderId)
                   if(index != -1){
                    temp = j
                     break;
                   }
                }
                  if(index != -1 && temp != -1){
                      finalStateData[temp] = finalData[index]
                      finalData.splice(index,1)
                  }
            }
             finalStateData = [...finalData, ...finalStateData]
       }
            
        let flag = localStorage.getItem('SortItem');
        flag = flag === 'true';
       
      if(flag ) {
          finalStateData.sort((a, b) => a.kotId - b.kotId);
      } else {  
          finalStateData.sort((a, b) => b.kotId - a.kotId); 
      }

       
       let arr = []
       finalStateData.map((val, i) => {
       if(val.orderStatus!=='DISPATCHED'){
           let obj = {}
           let count = 0
           let totalCount = 0
          let newCount=0
          let inProcessCount = 0
           obj['id'] = val.id
           obj['orderId'] = val.orderId
           obj['kotId'] = val.kotId
           obj['tokenId'] = val.tokenId
           obj['name'] = val.name
           obj['tableNo'] = val.tableNo
           obj['updatedAt'] = val.updatedAt
           obj['timestamp'] = val.timestamp
           obj['orderFrom']=val.orderFrom
           obj['orderSource'] = val.orderSource
           obj['subOrderFrom']=val.subOrderFrom
           obj['newOrderFlag']=val.newOrderFlag
           let arr2 = []
           val.orderList.map((k) => {
               let obj2 = {}
               obj2['subOrderId'] = k.subOrderId
               let arr3 = []
               k.services.map((s) => {
                   let obj3 = {...s}
                   if (s.station==this.state.station && s.status !='NEW' && s.status !='KOT_GENERATED' && s.status != "COMPLETED" ) {
                       count++
                       totalCount = totalCount + 1
                       arr3.push(obj3)

                   }
                   if(s.station==this.state.station && s.status=='NEW'){
                       // newCount = newCount + 1
                   }
                   if(s.station==this.state.station && s.status=='IN_PROCESS'){
                       inProcessCount = inProcessCount + 1
                   }
               })
               if (arr3.length > 0) {
                   obj2['services'] = arr3
                   arr2.push(obj2)
               }

           })
           if (arr2.length > 0) {
               obj['orderList'] = arr2
               obj['totalItems'] = count
               obj['newCount'] = newCount
               obj['inProcessCount'] = inProcessCount
               obj['totalCount'] = totalCount
               obj['tableOrderStatus'] = val.tableOrderStatus
               arr.push(obj)
           }
       }
           
       })

   
       let copy = arr && arr.length > 0 && arr.filter((item)=>{
            if( item.inProcessCount > 0 || item.newCount > 0){
               return item
            }
          
       })

       let TimeData = []
       let objDummy
       for(let i=0;i<copy.length;i++){
          objDummy = {
               kotId: copy[i].kotId,
               timestamp: copy[i].timestamp,
               orderId: copy[i].orderId
           }

           TimeData.push(objDummy)
       }

        if (this.state.clearIntervalFlag) {
            clearInterval(this.state.clearIntervalFlag)
        }

        let colorTimeObject = {};

        this.state.trackerConfig && this.state.trackerConfig.forEach(item => {
            colorTimeObject[item.color] = item.time;
        });
        let response
        if (this.state.trackerConfig) {
            response = calculateTime(TimeData, colorTimeObject)
        }
          
       if(copy){

           this.setState({
               createDataSpin: false,
               dataSource: copy,
               clearIntervalFlag: response
           })
           this.tempCalCulationOrderComp(TimeData,colorTimeObject,false)
       }else{
           this.setState({
               createDataSpin: false,
               dataSource: [],
               clearIntervalFlag: response
           })
       }
          

       this.cancleItemNotification(data,copyForCancleItemNotification, arr)
    }
    

    createDataV2 = (val, serviceStatus) => {
         
        let arr = this.state.dataSource

        
            let obj = {}
            let count = 0
            let totalCount = 0
            let newCount = 0
            let inProcessCount = 0
            obj['id'] = val.id
            obj['orderId'] = val.orderId
            obj['kotId'] = val.kotId
            obj['tokenId'] = val.tokenId
            obj['name'] = val.name
            obj['tableNo'] = val.tableNo
            obj['updatedAt'] = val.updatedAt
            obj['timestamp'] = val.timestamp
            obj['orderFrom']=val.orderFrom
            obj['orderSource'] = val.orderSource
            obj['subOrderFrom']=val.subOrderFrom
            // obj['newOrderFlag']=val.newOrderFlag
            let arr2 = []
            val.orderList.map((k) => {
                let obj2 = {}
                obj2['subOrderId'] = k.subOrderId
                obj2['customFeilds'] = k.customFeilds

                let arr3 = []
                k.services.map((s) => {

                    let obj3 = {...s}
                    // if (s.status == serviceStatus && s.station==this.state.station ) {
                    if (s.station==this.state.station && s.status!='COMPLETED' && s.status !='NEW' ) {

                        count++
                        totalCount = totalCount + 1
                        arr3.push(obj3)

                    }
                    if(s.station==this.state.station && s.status=='NEW'){
                        // newCount = newCount + 1
                    }
                    if(s.station==this.state.station && s.status=='IN_PROCESS'){
                        inProcessCount = inProcessCount + 1
                    }
                })
                if (arr3.length > 0) {
                    obj2['services'] = arr3
                    arr2.push(obj2)
                }

            })
    let foundIndex = arr.findIndex(x => x.kotId == val.kotId)
            if (arr2.length > 0) {
                obj['orderList'] = arr2
                obj['totalItems'] = count
                obj['totalCount'] = totalCount
                obj['newCount'] = newCount
                obj['inProcessCount'] = inProcessCount
                obj['tableOrderStatus'] = val.tableOrderStatus
                arr && arr.length> 0 && arr.map((p)=>{
                    p['newOrderFlag'] = false
                })
                 obj['newOrderFlag'] = true
              
         if (foundIndex >= 0) {
            arr[foundIndex] = obj 
         }else{       
            let flag = localStorage.getItem('SortItem');
            flag = flag === 'true';
            
            if(flag){
               arr.push(obj)   
            }else{
                arr.unshift(obj)
            }
         }
            }else if(foundIndex>=0){
                 arr.splice(foundIndex,1)
            }
        

        try{
            this.setState({
              updateflag:false
             })
            }catch(e){
                console.log('error catch::',e)
            }

            let copy = arr && arr.length > 0 && arr.filter((item)=>{
                if( item.inProcessCount > 0 || item.newCount > 0){
                   return item
                }
              
           })
          
           if(copy){
              
            this.setState({
               createDataSpin: false,
               dataSource: copy
            })
         }else{
            this.setState({
               createDataSpin: false,
               dataSource: []
            })
       }
    }

    StatusChanged = (e) => {
            this.getSiteOrder(e)
    }


    AcceptPrepared = (id, subid, sid, status,uId) => {
        // return
        this.enterLoading()
        this.setState({
            serviceId: uId,
            subOrderId:subid
        }, () => {
            this.updateStatus(id, subid, uId, status)
        }
        )
    }

    updateStatus = (id, subid, sid, status) => {
        this.setState({
            updateflag:true
        })
        this.enterLoading()
        this.props.client.mutate({
            mutation: updateTableOrderItemStatus,
            variables: {
                id: id,
                subOrderId: subid,
                serviceId: sid,
                status: status == 'NEW' ? 'IN_PROCESS' : 'PREPARED'
            }
        }).then(({ data }) => {
            message.success("Updated  Successfully")
            this.createOnUpdate(data.updateTableOrderItemStatus,this.state.kitchenStatus)
            this.setState({
                loading: false,
            })

        }).catch(err => {
            console.log('errrr', err)
        })
    }

    changeStation = (e) => {
        // console.log('eeee::', e)
    }

FlagStatusChange=()=>{
    this.setState({
    updateflag:false
    })
}

    AcceptAllUpdate=async(list,sid)=>{
        let arr = []

        for(let i=0;i<list.length;i++){
            for(let j=0;j<list[i].services.length;j++){
                if (list[i].services[j].status == 'NEW' ) {
                    let obj = {
                        subOrderId: list[i].subOrderId,
                        serviceId: list[i].services[j].uId,
                        status: 'IN_PROCESS'
                    }
                    arr.push(obj)
           
            }
          }
        }
          let obj2 = {
            id: sid,
            items: arr
        }
        this.acceptAllInBulk(obj2)
    }

    ReadyAllUpdate=async(list,sid)=>{
        let arr = []

        for(let i=0;i<list.length;i++){
            for(let j=0;j<list[i].services.length;j++){
                if (list[i].services[j].status == 'IN_PROCESS' ) {
                    let obj = {
                        subOrderId: list[i].subOrderId,
                        serviceId: list[i].services[j].uId,
                        status: 'PREPARED'
                    }
                    arr.push(obj)
           
            }
          }
        }
          let obj2 = {
            id: sid,
            items: arr
        }
        this.acceptAllInBulk(obj2)
    }
      
    AcceptKot=(list,sid,kotId)=>{

        this.loadingAcceptAll()
        this.setState({
            mainId: sid,
            kotId:kotId
        }, () => {
            this.AcceptKotUpdate(list,sid)
        }
        )
    }
    
    AcceptKotUpdate=async(list,sid)=>{
        let arr = []

        for(let i=0;i<list.length;i++){
            for(let j=0;j<list[i].services.length;j++){
                if (list[i].services[j].status == 'KOT_GENERATED' ) {
                    let obj = {
                        subOrderId: list[i].subOrderId,
                        serviceId: list[i].services[j].uId,
                        status: 'IN_PROCESS'
                    }
                    arr.push(obj)
           
            }
          }
        }
          let obj2 = {
            id: sid,
            items: arr
        }
        this.acceptAllInBulk(obj2)
    }

    acceptAllInBulk = (obj) => {
        this.props.client.mutate({
            mutation: updateTableOrderItemStatusInBulk,
            variables: obj
        }).then(({ data }) => {
            if (data.updateTableOrderItemStatusInBulk) {
                message.success("Updated  Successfully")
                this.setState({
                    acceptAllLoading:false
                })
                // resolve(true)
                this.createOnUpdate(data.updateTableOrderItemStatusInBulk,this.state.kitchenStatus)
            }
        }).catch((err) => {

            console.log(`Catch: updateTableOrderItemStatusInBulk:`,err)
        })
       


        //   })
    }

   AcceptallOrder=(list,sid)=>{
        this.loadingAcceptAll()
        this.setState({
            mainId: sid,
            kotId:kotId
        }, () => {
            this.AcceptAllUpdate(list,sid)
        }
        )
    }

    ReadyAllOrder=(list,sid,kotId)=>{
        this.loadingAcceptAll()
        this.setState({
            mainId: sid,
            kotId:kotId
        }, () => {
            this.ReadyAllUpdate(list,sid)
        }
        )
    }

    updateStatusAll = (id, subid, sid, status) => {
        return new Promise((resolve, reject)=> {
            // return
            this.setState({
                updateflag:true
            })
                this.props.client.mutate({
                    mutation: updateTableOrderItemStatus,
                    variables: {
                        id: id,
                        subOrderId: subid,
                        serviceId: sid,
                        status: status == 'NEW' ? 'IN_PROCESS' : 'PREPARED'
                    }
              }).then(({data})=>{
                // console.log('susscess',data)
                if(data.updateTableOrderItemStatus){
                    message.success("Updated  Successfully")
                    resolve(true)
                  }

              }).catch((err)=>{
                resolve(true)

                  console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
              })


              })
    }

     handleTabClick = (key) => {
        this.setState({tabs:key});
      };

      handleCancelNotificationModal = () =>{
         this.setState({showNotificationModal:false})
         AndroidPostMsg("STOP_BELL")
         const audioEl = document.getElementsByClassName("audio-element")[0]
         if (audioEl) {
             audioEl.pause()
         }
      }

      playAudio = () => {

        if (localStorage.getItem('mute') == 'true') {
             
            var Name = "Unknown OS";
            if (navigator.userAgent.indexOf("Win") != -1) Name = "Windows OS";
            if (navigator.userAgent.indexOf("Mac") != -1) Name = "Macintosh";
            if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux OS";
            if (navigator.userAgent.indexOf("Android") != -1) Name = "Android OS";
            if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";

            if(Name == 'Android OS' && window && window.ReactNativeWebView){
                AndroidPostMsg("START_BELL")
            }else{
            const audioEl = document.getElementsByClassName("audio-element")[0]
            if (audioEl) {
                audioEl.loop = true
                audioEl.play()
            }
           }
        }

    }

    render() {
        let stationobj = this.props.location.state && this.props.location.state?.stationDetails
        return (
            <App>
                <div>
              <audio className="audio-element">
                <source src="https://btoc-ecom-prod.s3.ap-south-1.amazonaws.com/KOT/kitchen1.mp3"></source>
              </audio>
              <audio className="audio-AddItem">
                <source src="https://btoc-ecom-prod.s3.ap-south-1.amazonaws.com/KOT/customerCall.mp3"></source>
              </audio>
            </div>
           
                {this.state.createDataSpin == true ? <Spin /> :
                      
                      this.state.platform == 'mobile' ?
                      <><div >
                      <h3 style={{
                          fontSize: '14px',
                          color: '#fff',
                          backgroundColor: '#57ad10', // Cool background color
                          padding: '3px',
                          borderRadius: '10px',
                          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', // Box-shadow
                          textAlign: 'center',
                      }}>{stationobj?.stationName ?? ''}</h3>
                  </div>
                        <Tabs style={{justifyItems:'center'}} className='tabsCustom tabcustomMob' defaultActiveKey="1" onTabClick={this.handleTabClick} >
                <TabPane   tab= "ORDER VIEW"  key="1">
                  <Services
                     station={this.state.station}
                     mainId={this.state.mainId}
                     siteId={this.state.siteId}
                     kotId={this.state.kotId}
                     getSiteOrder={this.getSiteOrder}
                      AcceptallOrder={this.AcceptallOrder}
                      AcceptKot={this.AcceptKot}
                      ReadyAllOrder={this.ReadyAllOrder}
                       acceptAllLoading={this.state.acceptAllLoading}
                        serviceId={this.state.serviceId}
                        subOrderId={this.state.subOrderId}
                        loading={this.state.loading}
                         kitchenStatus={this.state.kitchenStatus}
                         tableData={this.state.tableData}
                         AcceptPrepared={this.AcceptPrepared} serviceData={this.state.dataSource} trackerConfig={this.state.trackerConfig} clearIntervalFlag={this.state.clearIntervalFlag}  />
              </TabPane>

            <TabPane tab="ITEM VIEW"  key="2">
            {this.state.tabs == "2" &&
                  <ItemCount station={this.state.station} siteId={this.state.siteId}/>
            }
            </TabPane>

            <TabPane tab="READY TO SERVE"  key="3">
            {this.state.tabs == "3" &&
                  <ServeItems siteId={this.state.siteId}  tableData={this.state.tableData} station={this.state.station}/>
            }
            </TabPane>



                    </Tabs></> : <>
                            <div >
                                <h3 style={{
                                    fontSize: '20px',
                                    color: '#fff',
                                    backgroundColor: '#57ad10', // Cool background color
                                    padding: '3px',
                                    borderRadius: '10px',
                                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', // Box-shadow
                                    textAlign: 'center',
                                }}>{stationobj?.stationName ?? ''}</h3>
                            </div>
                    <Tabs style={{justifyItems:'center'}} className='tabsCustom tabsCustomDesktop' defaultActiveKey="1" onTabClick={this.handleTabClick} >
                <TabPane   tab= "ORDER VIEW"  key="1">
                  <Services
                     station={this.state.station}
                     mainId={this.state.mainId}
                     siteId={this.state.siteId}
                     kotId={this.state.kotId}
                     getSiteOrder={this.getSiteOrder}
                      AcceptallOrder={this.AcceptallOrder}
                      AcceptKot={this.AcceptKot}
                      ReadyAllOrder={this.ReadyAllOrder}
                       acceptAllLoading={this.state.acceptAllLoading}
                        serviceId={this.state.serviceId}
                        subOrderId={this.state.subOrderId}
                        loading={this.state.loading}
                         kitchenStatus={this.state.kitchenStatus}
                         tableData={this.state.tableData}
                         AcceptPrepared={this.AcceptPrepared} serviceData={this.state.dataSource} trackerConfig={this.state.trackerConfig} clearIntervalFlag={this.state.clearIntervalFlag} />
              </TabPane>

            <TabPane tab="ITEM VIEW"  key="2">
            {this.state.tabs == "2" &&
                  <ItemCount station={this.state.station} siteId={this.state.siteId}/>
            }
            </TabPane>

            <TabPane tab="READY TO SERVE"  key="3">
            {this.state.tabs == "3" &&
                  <ServeItems siteId={this.state.siteId}  tableData={this.state.tableData} station={this.state.station}/>
            }
            </TabPane>
                    </Tabs> 
                    </>

                }

                <Modal className='CanceledNotificationModal' visible={this.state.showNotificationModal} onCancel={this.handleCancelNotificationModal} maskClosable={false} closable={true} footer={null} destroyOnClose={true} >
                 <CancelKotNotify   TableData={this.state.tableData} platform={this.state.platform} Data={this.state.cancelNotification} handleCancelNotificationModal={this.handleCancelNotificationModal}/>                    
                </Modal>
            </App>
        )
    }
}
const wrappOrders = Form.create({ name: "JBooking_form" })(Orders);

export default withApollo(wrappOrders)