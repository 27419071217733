
import Amplify, { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import {
	ApolloProvider,
	ApolloClient,
	InMemoryCache,
	from
} from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import App from './components/app/mainApp'
import { AuthContext } from './context/authContext'
import './assets/custom.min.css'
import { split, HttpLink } from '@apollo/client';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { RetryLink } from '@apollo/client/link/retry';
import { Spin } from 'antd';
import { NetworkSwitchWrapper } from './store/networkDetectStore';
import { SignInHeader, Header, Footer } from './auth'
import './assets/themes.css';

Amplify.configure({
	region: process.env.REGION,
	userPoolId: process.env.AWS_USER_POOLS_ID,
	userPoolWebClientId: process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
});

class MainApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: undefined,
			userData: undefined,
			token: '',
			loading: true,
			errorCaptured: false,
			siteOption: '',
			authContextData: {}
		};
	}

	async componentDidMount() {
		try {

			let a = await Auth.currentSession()
			this.setState({
				token: a.getIdToken().getJwtToken()
			})
		} catch (err) {
			console.log(err);
		}

		Auth.currentAuthenticatedUser()
			.then((res) => {

				this.setState({
					role: res.attributes['custom:role'] ? res.attributes['custom:role'] : 'Mer_Admin',
					userData: {
						email: res.attributes.email,
						username: res.username,
						phoneNumber: res.attributes.phone_number,
						fqdnMut: res.attributes['custom:fqdnMut'],
						siteId: res.attributes['custom:siteId']
					},
					loading: false,
					authContextData: {
						role: res.attributes['custom:role'] ? res.attributes['custom:role'] : 'Mer_Admin',
						userData: {
							email: res.attributes.email,
							username: res.username,
							phoneNumber: res.attributes.phone_number,
							fqdnMut: res.attributes['custom:fqdnMut'],
							siteId: res.attributes['custom:siteId']
						},
						username: res.username
					}
				})


			}).catch((err) => {
				console.log('catch::::', err)
				console.log(err);
			})


	}



	updateAuthContext = (data) => {
		if (!data) {
			return
		}
		let obj = { ...this.state.authContextData }
		this.setState({
			authContextData: { ...obj, ...data }
		})
	}

	logout = () => {
		localStorage.clear();
		window.location.reload()
	}

	render() {
	
		const config = {
			url: process.env.ENDPOINT,
			region: process.env.REGION, // or whatever your region is
			auth: {
				type: process.env.AUTH_TYPE,
				jwtToken: async () =>
					(await Auth.currentSession()).getIdToken().getJwtToken()
			},
			complexObjectsCredentials: () => Auth.currentCredentials(),
			disableOffline: false,
		};

		const httpLink = new HttpLink({
			uri: config.url
		});

		const authLink = createAuthLink(config)

		const handShake = createSubscriptionHandshakeLink(
			{
				auth: {
					type: config.auth.type,
					jwtToken: config.auth.jwtToken,
				},
				region: config.region,
				url: config.url,
			},
			httpLink
		)

		const splitLink = split(op => {
			const { operation } = op.query.definitions[0]

			if (operation === 'subscription') {
				return false;
			}

			return true;
		},
			httpLink,
			handShake)

		const client = new ApolloClient({
			cache: new InMemoryCache(),
			link: from([
				new RetryLink({
					
					attempts: (count, operation, error) => {
						console.log(count, operation, error);
						let flag = this.state.errorCaptured;
						if (count <= 4) {
							this.setState({
								errorCaptured: true
							})
							flag = true
						}
						
						return !flag

					},

				}),
				authLink,
				splitLink,

			])
		});


		if (this.state.loading == true) {
			return (
				<Spin />
			)
		}

		return (

			<ApolloProvider client={client}>
				<AuthContext.Provider value={{ ...this.state.authContextData, updateAuthContext: this.updateAuthContext }}>
					{/* <NetworkSwitchWrapper errorCaptured={this.state.errorCaptured}> */}
						<>
							<App {...this.state} />
						</>
					{/* </NetworkSwitchWrapper> */}
				</AuthContext.Provider>
			</ApolloProvider>

		);
	}
}

export default withAuthenticator(MainApp, {
	hideSignUp: true,
	components: {
		Header,
		SignIn: {
			Header: SignInHeader,
		},
		Footer
	}
});
