import gql from 'graphql-tag';

export default gql`
query getSiteOrderByItemStatus( 
    $station: String! 
    $status: [RESTAURANT_ORDER_STATUS!]!
    $skip: Int! 
    $limit: Int!
    ){
    getSiteOrderByItemStatus(
      station: $station 
      status: $status
      skip: $skip 
      limit: $limit
    ){
      id
      orderId
      noOfGuest
      counterId
      tokenId
      siteId
      name
      date
      timestamp
      updatedAt
      tableNo
      orderFrom
      orderSource
      subOrderFrom
      orderStatus
      orderList{
        subOrderId
        createdAt
        services{
          serviceId
          serviceName
          qty
          status
          pmInventory
          purchaseItemRef
          tax
          taxType
          isUrgent
          addons{
            serviceName
            serviceRate
             qty
           }
          uId
          attachedCat
          instruction
          station
          kotId
          kotId
          inprocessAt
          preparedAt
          completedAt
        }
      }
    }
  }
  
`