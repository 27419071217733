import React from 'react'
import unslugify from "unslugify";

import { Card, Row, Col, Button, List, Select, Table, Checkbox, Dropdown, Menu, Radio } from "antd";
import { Tabs } from 'antd';
import getSiteOrderByItemStatus from '../../queries/getSiteOrderByItemStatus';
import tableOrderUpdatedV2 from '../../subscription/tableOrderUpdatedV2'
import { withRouter } from 'react-router';
import moment from 'moment'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const { TabPane } = Tabs;
const { Option } = Select;

class NewServices extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            createDataSpin: false,
            dataSource: []
        }
    }


    componentDidMount = () => {

        //console.log("state for query",this.props)

        // this.getSiteOrder()

        if (window.screen.width >= 700) {
            //   1280
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }

        // this.tableOrderUpdatedV2NewOrdersSubscriptionCall()
    }

    

    getSiteOrder = () => {
        // this.loadingSpin()
        this.props.client.query({
            query: getSiteOrderByItemStatus,
            fetchPolicy: 'network-only',
            variables: {
                station: this.props.station,
                // station:'kitchen',
                status: ['KOT_GENERATED'],
                skip: 0,
                limit: 1000
                // tableNo:'1'
            }
        })
            .then(({ data }) => {
                // console.log("Data:::", data)
                if (data.getSiteOrderByItemStatus != null) {

                    let dataCopy = JSON.parse(JSON.stringify(data.getSiteOrderByItemStatus))
                    this.createData(dataCopy)
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: query error: ${JSON.stringify(err, null, 2)}`, err)
            })
    }

    tableOrderUpdatedV2NewOrdersSubscriptionCall = () => {
        let that = this

        this.subscriptionV2 = this.props.client.subscribe({
            query: tableOrderUpdatedV2,
            variables: {
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({ data }) => {
                //  console.log("restaurantData tableOrderUpdatedV2 ::",data.tableOrderUpdatedV2)
                let item = data.tableOrderUpdatedV2

                if (item.tableOrderStatus == "CLOSED") {
                    // console.log("Order Removed::", item.tableOrderStatus)
                    let FilterDataSOurce = this.state.dataSource.filter((order) => order.kotId != item.kotId)


                    if (FilterDataSOurce) {
                        this.setState({ dataSource: FilterDataSOurce })
                    } else {
                        this.setState({ dataSource: [] })
                    }
                }

                if (item.tableOrderStatus != "CLOSED") {
                    this.createOnUpdate(data.tableOrderUpdatedV2)
                }
            },
            error(err) { console.error('err', err); },
        });
    }

    filterBYKOtID = (orders) => {
        // console.log("orders ::", orders)

        let response = [];
        let tmpKot = {};
        let newSubOrder = null;

        orders.forEach(order => {
            order.orderList.forEach(subOrder => {

                subOrder.services.forEach(service => {
                    // service.subOrderId = subOrder.subOrderId

                    if (service.kotId) {

                        if (!tmpKot[service.kotId]) {

                            tmpKot[service.kotId] = Object.assign({}, order);

                            delete tmpKot[service.kotId].kotNo;

                            tmpKot[service.kotId].orderList = []

                            tmpKot[service.kotId].kotId = service.kotId

                            newSubOrder = {

                                "subOrderId": subOrder.subOrderId,

                                "createdAt": subOrder.createdAt,

                                "services": [Object.assign({}, service)]

                            }

                            tmpKot[service.kotId].orderList.push(newSubOrder);

                        } else {

                            let index = tmpKot[service.kotId].orderList.findIndex(obj => obj.subOrderId == subOrder.subOrderId);

                            if (index == -1) {

                                newSubOrder = {
                                    "subOrderId": subOrder.subOrderId,
                                    "createdAt": subOrder.createdAt,
                                    "services": [Object.assign({}, service)]
                                }

                                tmpKot[service.kotId].orderList.push(newSubOrder);

                            } else {
                                tmpKot[service.kotId].orderList[index].services.push(Object.assign({}, service))
                            }

                            // service.subOrderId = subOrder.subOrderId

                            // tmpKot[service.kotId].orderList[0].services.push(Object.assign({},service));
                        }
                    }
                });
            });


            Object.keys(tmpKot).forEach(kot => {
                response.push(Object.assign({}, tmpKot[kot]))
            });

            // console.log("-------------------")
            tmpKot = {}
        });

        // console.log("response ::", response.length)
        // console.log("response ::", response)

        return response

    }

    createData = (data) => {

        let finalData = this.filterBYKOtID(data)


        let arr = []
        finalData.map((val, i) => {
            if (val.orderStatus !== 'DISPATCHED') {
                let obj = {}
                let count = 0
                let totalCount = 0
                let newCount = 0
                let inProcessCount = 0
                obj['id'] = val.id
                obj['orderId'] = val.orderId
                obj['kotId'] = val.kotId
                obj['tokenId'] = val.tokenId
                obj['name'] = val.name
                obj['tableNo'] = val.tableNo
                obj['updatedAt'] = val.updatedAt
                obj['timestamp'] = val.timestamp
                obj['orderFrom'] = val.orderFrom
                obj['subOrderFrom'] = val.subOrderFrom
                obj['newOrderFlag'] = val.newOrderFlag
                let arr2 = []
                val.orderList.map((k) => {
                    let obj2 = {}
                    obj2['subOrderId'] = k.subOrderId
                    let arr3 = []
                    k.services.map((s) => {
                        let obj3 = { ...s }
                        if (s.station == this.props.station) {
                            count++
                            totalCount = totalCount + s.qty
                            // obj3['serviceId'] = s.serviceId
                            // obj3['serviceName'] = s.serviceName
                            // obj3['qty'] = s.qty
                            // obj3['status'] = s.status
                            // obj3['station'] = s.station
                            // obj3['isUrgent'] = s.isUrgent
                            // obj3['instruction'] = s.instruction
                            arr3.push(obj3)

                        }
                        if (s.station == this.props.station && s.status == 'NEW') {
                            // newCount = newCount + 1
                        }
                        if (s.station == this.props.station && s.status == 'IN_PROCESS') {
                            inProcessCount = inProcessCount + 1
                        }
                    })
                    if (arr3.length > 0) {
                        obj2['services'] = arr3
                        arr2.push(obj2)
                    }

                })
                if (arr2.length > 0) {
                    obj['orderList'] = arr2
                    obj['totalItems'] = count
                    obj['newCount'] = newCount
                    obj['inProcessCount'] = inProcessCount
                    obj['totalCount'] = totalCount
                    obj['tableOrderStatus'] = val.tableOrderStatus
                    arr.push(obj)
                }
            }
            // console.log('Count::', count)
        })

        // console.log("setState Data:::", arr)

        this.setState({
            createDataSpin: false,
            dataSource: arr
        })
    }

    createOnUpdate=(data)=>{
      
        // console.log("called createOnUpdate NewOrder:::",data)
        let updatedData = []
        updatedData.push(data)
 
        let finalData = this.filterBYKOtID(updatedData)
 
        // console.log("finalData:::",finalData)
         
        let finalStateData = this.state.dataSource
 
        // console.log("DataSOurce::0",finalStateData)
 
        if(finalStateData.length == 0){
        //  console.log("Replaced:::",finalStateData)
         finalStateData = [...finalData, ...finalStateData]
        }else{
            
             for(let i=0; i<finalStateData.length;i++){
                 let index = -1
                 let temp = -1
                 for(let j=0; j<finalStateData.length;j++){
                    index = finalData.findIndex(item => item.kotId == finalStateData[j].kotId && item.orderId == finalStateData[j].orderId)
                    if(index != -1){
                     temp = j
                      break;
                    }
                 }
                   if(index != -1 && temp != -1){
                    //  console.log("inside If:::", index,temp )
                       finalStateData[temp] = finalData[index]
                       finalData.splice(index,1)
                   }
             }
 
            //  console.log("after Splice:::",finalData)
 
              finalStateData = [...finalData, ...finalStateData]
 
        }
              
        //  console.log("finalStateDataMAde::",finalStateData)
        
        let arr = []
        finalStateData.map((val, i) => {
        if(val.orderStatus!=='DISPATCHED'){
            let obj = {}
            let count = 0
            let totalCount = 0
           let newCount=0
           let inProcessCount = 0
            obj['id'] = val.id
            obj['orderId'] = val.orderId
            obj['kotId'] = val.kotId
            obj['tokenId'] = val.tokenId
            obj['name'] = val.name
            obj['tableNo'] = val.tableNo
            obj['updatedAt'] = val.updatedAt
            obj['timestamp'] = val.timestamp
            obj['orderFrom']=val.orderFrom
            obj['subOrderFrom']=val.subOrderFrom
            obj['newOrderFlag']=val.newOrderFlag
            let arr2 = []
            val.orderList.map((k) => {
                let obj2 = {}
                obj2['subOrderId'] = k.subOrderId
                let arr3 = []
                k.services.map((s) => {
                    let obj3 = {...s}
                    if (s.station==this.props.station && s.status == 'KOT_GENERATED' ) {
                        count++
                        totalCount = totalCount + s.qty
                        // obj3['serviceId'] = s.serviceId
                        // obj3['serviceName'] = s.serviceName
                        // obj3['qty'] = s.qty
                        // obj3['status'] = s.status
                        // obj3['station'] = s.station
                        // obj3['isUrgent'] = s.isUrgent
                        // obj3['instruction'] = s.instruction
                        arr3.push(obj3)
 
                    }
                    if(s.station==this.props.station && s.status=='NEW'){
                        // newCount = newCount + 1
                    }
                    if(s.station==this.props.station && s.status=='IN_PROCESS'){
                        inProcessCount = inProcessCount + 1
                    }
                })
                if (arr3.length > 0) {
                    obj2['services'] = arr3
                    arr2.push(obj2)
                }
 
            })
            if (arr2.length > 0) {
                obj['orderList'] = arr2
                obj['totalItems'] = count
                obj['newCount'] = newCount
                obj['inProcessCount'] = inProcessCount
                obj['totalCount'] = totalCount
                obj['tableOrderStatus'] = val.tableOrderStatus
                arr.push(obj)
            }
        }
            // console.log('Count::', count)
        })
 
        // console.log("arrCheck::",arr)
 
            this.setState({
                createDataSpin: false,
                dataSource: arr
            })
 
     }

    onTabChange = (e) => {
        // console.log("onTabChange:::", e)
        this.setState({ type: e })
    }

    showMenuName = (s) =>{

        let name = ''
         if(s.subOrderFrom){
              name = name + unslugify(s.subOrderFrom).toUpperCase()
              
              if(s.orderSource){
                name = name + " (" + unslugify(s.orderSource).toUpperCase() + " )"
              }
         }else{
            name = unslugify(s.orderFrom).toUpperCase()
         }
           
         return name
       
    }


    render() {
        //  let status = this.props.kitchenStatus
        let services = this.props.dataSource
        let TableData = this.props.tableData
        // console.log('this.state.dataSource newOrders:::', services)

        // console.log("loading::",this.props.kotId  && this.props.acceptAllLoading)

        return (

            <div>
                {this.state.platform == 'desktop' ?
                    <>
                        {services && services.length > 0 ?
                            <Row gutter={16}>
                                {services.map((s) => {

                                    let tableName = ""

                                    TableData && TableData.length > 0 && TableData.map((item) => {
                                        if (item.typeValue == s.tableNo) {
                                            tableName = item.tableName
                                        }
                                    })
                                    return (
                                        <>
                                            {s.totalItems > 0 &&
                                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Card className="kitchenCards" id={`NewKotOrder${s.kotId}${s.orderId}`} style={{ border: s.newOrderFlag && '2px solid red' }}>
                                                        <Row>

                                                            <Col span={s.orderFrom == 'dine-in' ? 8 : 4} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                <p className="kitcheNames"  style={{ fontSize: '17px' }}>{s.name}</p>
                                                                {/* <div style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
                                                                    <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Order Id:</span> {s.orderId}</p>
                                                                    <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Token No:</span> {s.tokenId}</p>
                                                                    <p style={{ marginBottom: '0px' }}><span className="kitcheNames">KOT NO :</span> {s.kotId}</p>
                                                                </div> */}



                                                            </Col>
                                                            <Col span={s.orderFrom == 'dine-in'? 10 : 14} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                <div style={{ display: this.state.platform == 'desktop' && 'flex' }}>
                                                                    <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                        <Button
                                                                            style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : s.orderFrom == 'online-menu' ? '#4e45a0' : '#f36b0a', height: '30px' }}>
                                                                             <span style={{ color: 'white' }}>{this.showMenuName(s)}</span></Button>
                                                                    </p>
                                                                    {s.orderFrom == 'dine-in' &&
                                                                        <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                            <Button style={{ borderRadius: '5px', background: '#27cfa0', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                {/* <span className="kitcheNames" style={{ color: 'white' }}>Table:</span> {s.tableNo ? s.tableNo.split('::')[1] : ''} */}
                                                                                <span className="kitcheNames" style={{ color: 'white' }}>{tableName}</span>
                                                                            </span></Button>
                                                                        </p>
                                                                    }
                                                                    <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                        <Button style={{ borderRadius: '5px', background: '#9db519', height: '30px' }}><span style={{ color: 'white' }}>
                                                                            <span className="kitcheNames" style={{ color: 'white' }}>ITEMS:</span> {s.totalCount}
                                                                        </span></Button>
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col span={6} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                                </p>

                                                            </Col>
                                                        </Row>
                                                        <div>
                                                                 <div style={{ display: "flex",marginTop:"10px", marginBottom:"10px"}}>
                                                                            <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Order Id:</span> {s.orderId}</p>
                                                                            <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Token No:</span> {s.tokenId}</p>
                                                                            <p style={{ marginBottom: '0px' }}><span className="kitcheNames">KOT NO :</span> {s.kotId}</p>
                                                                 </div>
                                                                </div> 
                                                        <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
                                                        {s.orderList && s.orderList.map((order) => {
                                                            return (
                                                                <div
                                                                    id="scrollableDiv"
                                                                    style={{
                                                                        height: 'auto',
                                                                        overflow: 'auto',
                                                                        padding: '0 3px',
                                                                        // border: '1px solid rgba(140, 140, 140, 0.35)',
                                                                    }}
                                                                >
                                                                    <List
                                                                        className="kitchenList"
                                                                        dataSource={order.services}
                                                                        renderItem={item => (
                                                                            <>
                                                                                {/* {item.status == status && */}
                                                                                <List.Item key={item.serviceId} className="kitchenListItem">
                                                                                    <List.Item.Meta
                                                                                        className="listData"
                                                                                        // avatar={<Avatar src={item.picture.large} />}
                                                                                        title={<Row style={{ marginTop: '10px' }}>
                                                                                            <Col xs={{ span: 16 }} sm={{ span: 10 }} md={{ span: 16 }} lg={{ span: 16 }}><span style={{ fontWeight: 'bold', fontSize: '16px' }}>{item.serviceName}</span>
                                                                                                {item.addons &&
                                                                                                    <p style={{ marginBottom: '0px' }}>(
                                                                                                        {item.addons && item.addons.map((add, i) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <span>{add.serviceName}</span>
                                                                                                                    {item.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        }
                                                                                                        )
                                                                                                    </p>
                                                                                                }
                                                                                                {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600, marginTop: '-5px', marginBottom: '0px', fontSize: '13px' }}>{`(${item.instruction})`}</p>}

                                                                                            </Col>

                                                                                            <Col xs={{ span: 20 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} >Qty: {item.qty}</Col>
                                                                                            {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }} ><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                        </Row>}
                                                                                    />
                                                                                    {item.status == 'KOT_GENERATED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                KOT SENT</p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.status == "COMPLETED" &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                COMPLETED</p>
                                                                                        </div>
                                                                                    }
                                                                                    {item.status == 'CANCELLED' &&
                                                                                        <div style={{ margin: 'auto', marginRight: '18px' }}>
                                                                                            <p style={{ textAlign: 'center', color: 'red', fontWeight: 600, marginTop: '10px' }}>
                                                                                                CANCELLED</p>
                                                                                        </div>
                                                                                    }
                                                                                </List.Item>

                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            )
                                                        })}

                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                                            <Button loading={this.props.kotId == s.kotId  && this.props.mainId == s.id && this.props.acceptAllLoading} style={{ backgroundColor: "#ff4045", color: "white" }}  onClick={() => this.props.KotAccept(s.orderList, s.id, s.kotId)}>Accept KOT </Button>
                                                        </div>

                                                   
                                                    </Card>
                                                </Col>
                                            }
                                        </>

                                    )
                                })
                                }
                            </Row>
                            :
                            <p style={{ textAlign: 'center', marginTop: '50px' }}><img src='/public/nodata.png' style={{ height: '200px' }} /></p>
                        }

                    </>

                    :
                         
                    <>
                          {services && services.length > 0 ?
                            <Row gutter={16}>
                                {services && services.length > 0 && services.map((s) => {
                                    let tableName = ""

                                    TableData && TableData.length > 0 && TableData.map((item) => {
                                        if (item.typeValue == s.tableNo) {
                                            tableName = item.tableName
                                        }
                                    })
                                    return (
                                        <>
                                            {s.totalItems > 0 &&
                                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Card className="kitchenCards" id={`NewKotOrder${s.kotId}${s.orderId}`}  style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Row>
                                                                <Col span={12}>
                                                                    <p style={{ marginBottom: '0px', textAlign: 'left' }}>
                                                                    <span style={{ color: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', fontWeight: 600, fontSize: '17px', }}>{this.showMenuName(s)}</span>
                                                                    </p>
                                                                </Col>
                                                                    
                                                                <Col span={12}>
                                                                    {s.orderFrom == 'dine-in' &&
                                                             

                                                                    <p style={{ marginBottom: '0px', textAlign: 'justify' }}>
                                                                        {/* <Button style={{ borderRadius: '5px', background: '#c38c12', height: '30px',width:'100%' }}> */}
                                                                        {/* <span className="kitcheNames"  style={{fontSize:'16px'}}>{s.name}</span>  */}
                                                                        {/* </Button> */}
                                                                        <Button style={{ borderRadius: '5px', background: '#409ee9', height: '30px' }}><span style={{ color: 'white' }}>
                                                                            {/* <span className="kitcheNames" style={{ color: 'white', fontSize: '12px' }}>TABLE NO:&nbsp;{s.tableNo ? s.tableNo.split('::')[1] : ''}</span> */}
                                                                            <span className="kitcheNames" style={{ color: 'white', fontSize: '12px' }}>{tableName}</span>
                                                                        </span>
                                                                        </Button>
                                                                    </p>
                                                                    }
                                                                    </Col>
                                                                  
                                                                </Row>
                                                               
                                                            </Col>
                                                            <Col span={12}>
                                                                <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                           
                                                            <Col span={24} style={{ textAlign: s.orderFrom == 'dine-in' ? 'end' : 'justify', marginTop: "4px" }} >
                                                                <div style={{ display: "flex" }}>
                                                                    <p style={{ marginBottom: '0px', marginRight: "15px", }}>
                                                                        <span className="kitcheNames" style={{ lineHeight: '26px' }}>ORDER ID:{s.orderId ? s.orderId : ''}</span>
                                                                    </p>
                                                                    <p style={{ marginBottom: '0px', marginRight: "15px", }}>
                                                                        <span className="kitcheNames" style={{ lineHeight: '26px' }}>TOKEN NO:{s.tokenId ? s.tokenId : ''}</span>
                                                                    </p>
                                                                    <p style={{ marginBottom: '0px', marginRight: "15px", }}>
                                                                        <span className="kitcheNames" style={{ lineHeight: '26px' }}>KOT NO:{s.kotId ? s.kotId : ''}</span>
                                                                    </p>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        <Row gutter={8} style={{ marginBottom: '10px', marginTop: '0px' }}>
                                                            <Col span={24} style={{ textAlign: 'justify' }}>
                                                                <span className="kitcheNames" style={{ fontSize: '16px' }}>{s.name}</span>

                                                            </Col>

                                                        </Row>
                                                        <hr style={{ marginTop: '0px', marginBottom: '2px' }} />
                                                        {s.orderList && s.orderList.map((order) => {
                                                            return (
                                                                <div
                                                                    id="scrollableDiv"
                                                                    style={{
                                                                        height: 'auto',
                                                                        overflow: 'auto',
                                                                        padding: '0 3px',
                                                                        borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                                                                    }}
                                                                >
                                                                    <List
                                                                        className="kitchenList"
                                                                        dataSource={order.services}
                                                                        renderItem={item => (
                                                                            <>
                                                                                {/* {item.status == status && */}
                                                                                <List.Item key={item.serviceId} className="kitchenItemsBorder">
                                                                                    <List.Item.Meta
                                                                                        className="listData"
                                                                                        // avatar={<Avatar src={item.picture.large} />}
                                                                                        title={<Row gutter={16}>
                                                                                            <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 18 }} lg={{ span: 18 }}>{item.serviceName}
                                                                                                {item.addons &&
                                                                                                    <p style={{ marginBottom: '0px' }}>(
                                                                                                        {item.addons && item.addons.map((add, i) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <span>{add.serviceName}</span>
                                                                                                                    {item.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        }
                                                                                                        )
                                                                                                    </p>
                                                                                                }
                                                                                            </Col>
                                                                                            <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} >Qty: {item.qty}</Col>
                                                                                            {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }} lg={{ span: 2 }} style={{ marginTop: '-7px' }}><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                            {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600 }}>{`(${item.instruction})`}</p>}
                                                                                        </Row>
                                                                                        }

                                                                                    />
                                                                                    {item.status == 'NEW' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                <Button loading={this.props.serviceId == item.uId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#23AE5C', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status, item.uId)}><span style={{ fontSize: '12px' }}>ACCEPT</span></Button></p></div>
                                                                                    }
                                                                                    {item.status == 'KOT_GENERATED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                            KOT SENT</p>
                                                                                        </div>
                                                                                    }
                                                                                    {item.status == 'IN_PROCESS' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                <Button loading={this.props.serviceId == item.uId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status, item.uId)}><span style={{ fontSize: '12px' }}>Ready</span></Button></p></div>

                                                                                    }
                                                                                    {item.status == 'PREPARED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                Ready to Serve</p>
                                                                                        </div>
                                                                                    }
                                                                                    {item.status == "COMPLETED" &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                COMPLETED</p>
                                                                                        </div>

                                                                                    }

                                                                                    {item.status == 'CANCELLED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: 'red', fontWeight: 600 }}>
                                                                                                CANCELLED</p>
                                                                                        </div>
                                                                                    }
                                                                                </List.Item>
                                                                                {/* } */}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            )
                                                        })}

                                                        <Row gutter={10} style={{ marginTop: '5px' }}>
                                                            <Col span={8}>

                                                                <p style={{ marginBottom: '0px', textAlign: 'justify', marginTop: '0px' }}>
                                                                    <Button style={{ borderRadius: '5px', width: '100%', background: 'none', height: '30px' }}><span style={{ color: 'white' }}>
                                                                        <span className="kitcheNames" style={{ fontSize: '12px', fontWeight: 700 }}>ITEMS:&nbsp; {s.totalCount}</span>
                                                                    </span>
                                                                    </Button>
                                                                </p>
                                                            </Col>
                                                           
                                                        </Row>

                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                                            <Button loading={this.props.kotId == s.kotId && this.props.mainId == s.id && this.props.acceptAllLoading} style={{ backgroundColor: "#ff4045", color: "white" }}  onClick={() => this.props.KotAccept(s.orderList, s.id, s.kotId)}>Accept KOT </Button>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            }
                                        </>

                                    )
                                })
                                }
                            </Row>
                            :

                            <div >
                                <p style={{ textAlign: 'center', marginTop: '50px' }}><img src='/public/nodata.png' style={{ height: '200px' }} /></p>
                            </div>
                        }


                    </>
                }
            </div>
        )
    }
}
const wrappServices = Form.create({ name: "JBooking_form" })(NewServices);

export default withApollo(wrappServices);
