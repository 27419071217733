import React, { Suspense, lazy } from 'react';
import GetSite from '../../queries/getSite'
import compose from 'lodash/flowRight';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Auth } from "@aws-amplify/auth";
import { SelectCurrencyContext, itemsData } from '../../context/CurrencyContext'
import { AuthContext } from '../../context/authContext';
import getJewelSiteSetting from '../../queries/getJewelSiteSetting'
import getRecahoAppVersion from '../../queries/getRecahoAppVersion'
import getPlatformServiceBySlugName from '../../queries/getPlatformServiceBySlugName';
import { SettingContext } from '../../context/SettingContext';
// import WaiterApp from './waiterApp'
// import KitchenApp from './kitchenApp'
import KitchenAppStation from './kitchenAllStationApp'
import { Button, Spin, message } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
import getCustomLabels from '../../utils/getCustomLables';
import getSiteSubRoleByUserName from '../../queries/getSiteSubRoleByUserName';
import getServiceTypes from '../../queries/getServiceTypes';
import { getServiceTypeSettingInBulk } from '../../utils/getServiceTypeSettingInBulk';
import { gerenricQueryFunction } from '../../utils/genericQueryFunction';
import getCategoryBySiteIdV2 from '../../queries/getCategoryBySiteIdV2';
import GetPlatForm from '../../queries/getPlatformServicesByAdmin';
const WaiterApp = lazy(() => import('./waiterApp'))
const KitchenApp = lazy(() => import('./kitchenApp'))

const LoadingComponent = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            flexDirection: "column",
            backgroundColor: "#f0f2f5"  // Light background color for contrast
          }}>
            <div style={{
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: "#ffffff",  // White background for the message box
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  // Subtle shadow for a professional look
              maxWidth: "80%",  // Responsive width
            }}>
              <h3 style={{
                fontSize: "20px",
                fontWeight: "600",
                color: "#333",
                marginBottom: "5px",
              }}>
                Downloading App Settings
              </h3>
              {/* <p style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#555",
                margin: "0",
              }}>
                Settings And Configuration...
              </p> */}
              <Spin size="small" style={{ marginTop: "10px" }} />
            </div>
             {/* Larger spin and margin-top */}
          </div>
    )
}

const Icon = (props) => {
    if (props.type == 'ExclamationCircleFilled') {
        return (
            <ExclamationCircleFilled {...props} />
        )
    } else {
        return null
    }
}

class MainApp extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            siteType: '',
            authorized: false,
            curr: '',
            currency: itemsData.curr,
            domain: '',
            siteOption: '',
            siteSettings: {},
            platform: "mobile",
            loading: true,
            role: null,
            permissions: null,
            allSettings: null,
            menuTypes: null,
            categoryData: null,
            userData: null,
            platformConfig: null
        }
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        if (nextProps.data.getSite === undefined || nextProps.data.getSite == null) {
            return null
        }
        //TODO - version mapper

        if (nextProps.data.getSite != undefined && nextProps.data.getSite !== null &&
            (nextProps.data.getSite.siteType === 'O2OCommerce' || nextProps.data.getSite.siteType === 'MARKETING')) {
            return {
                siteType: nextProps.data.getSite.siteType,
                currency: nextProps.data.getSite.basicSiteSetting.curr,
                siteOption: nextProps.data.getSite.basicSiteSetting.siteOptions,
                domain: nextProps.data.getSite.domain,
            }
        }
    }

    async componentDidMount() {

        let loader = document.getElementById('siteloader1')
        if (loader) {
            loader.remove()
        }

        await Auth.currentUserInfo()
            .then((data) => {
                let Device = this.checkDevice()
                let uname = data.username
                let siteId = data.attributes['custom:siteId']
                if (Device == 'Android OS') {
                    let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${uname}.deviceKey`)
                    let data = {
                        deviceId: deviceId,
                        siteId: siteId,
                        username: uname
                    }
                    let obj = {
                        "eventName": "LOGIN",
                        "payload": JSON.stringify(data)
                    }
                    window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                }

                if (Device == 'iOS') {
                    let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${uname}.deviceKey`)
                    let data = {
                        deviceId: deviceId,
                        siteId: siteId,
                        username: uname
                    }
                    let obj = {
                        "eventName": "LOGIN",
                        "payload": JSON.stringify(data)
                    }
                    window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                }

                if (data.attributes['custom:role'] == undefined) {
                    localStorage.setItem('role', 'Mer_Admin')
                    this.setState({
                        role: undefined
                    })
                } else {
                    localStorage.setItem('role', data.attributes['custom:role'])
                    this.setState({
                        role: data.attributes['custom:role']
                    })
                }
                localStorage.setItem('ph_no', data.attributes['phone_number'])
                localStorage.setItem('siteId', data.attributes['custom:siteId'])
                localStorage.setItem('domain', data.attributes['custom:fqdnMut'])

                if (data.attributes['custom:role'] == "WAITER") {
                    this.getCategory()
                    this.GetPlatForm()
                        .then(data => {
                            this.setState({ platformConfig: data });
                        })
                        .catch(error => {
                            this.setState({ platformConfig: {} });
                        });
                }
                this.getPermissions(uname)
            })
            .catch(err => console.log(err));

        this.getAppVersion()
        this.getSiteSettings()
        this.getMenu()
        this.getPlatform()
        this.getPlatformServiceBySlugName()
        try {
            let r = await this.registerPushNotif();
        } catch (error) {

        }
    }
    checkDevice = () => {
        var Name = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") != -1) Name = "Windows OS";
        if (navigator.userAgent.indexOf("Mac") != -1) Name = "Macintosh";
        if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux OS";
        if (navigator.userAgent.indexOf("Android") != -1) Name = "Android OS";
        if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";
        return Name
    }
    getPlatform = () => {
        if (window.screen.width >= 700) {
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }
    }

    getPermissions = (username) => {

        this.setState({
            loading: true
        })
        this.props.client.query({
            query: getSiteSubRoleByUserName,
            variables: {
                userName: username
            }
        }).then(({ data }) => {
            let permissions = data.getSiteSubRoleByUserName.permissions
            this.setState({ permissions: permissions, userData: data.getSiteSubRoleByUserName })

        }).catch((err) => {
            this.setState({
                loading: false
            })
            console.log(err);
        })
    }

    getCategory = async () => {
        const response = await gerenricQueryFunction(getCategoryBySiteIdV2, this.props.client, { limit: 1000 }, "cache-first", "getCategoryBySiteIdV2");

        if (response) {
            if (response.isError) {
                message.error("Something Went Wrong")
            } else {
                if (response.data) {
                    this.setState({
                        categoryData: response.data
                    })
                } else {
                    message.error('');
                }
            }
        }

    }

    GetPlatForm = () => {
        return new Promise((resolve, reject) => {
            this.props.client
                .query({
                    query: GetPlatForm,
                    fetchPolicy: "network-only",
                })
                .then(({ data }) => {
                    if (data && data.getPlatformServicesByAdmin) {
                        resolve(data.getPlatformServicesByAdmin);
                    } else {
                        resolve({})
                    }

                });
        });
    };

    getSiteSettings = () => {
        this.setState({
            loading: true
        })
        this.props.client.query({
            query: getJewelSiteSetting
        }).then(({ data }) => {
            if (data.getJewelSiteSetting) {
                let settingObj = { ...data.getJewelSiteSetting }
                let labelsObj = getCustomLabels(data.getJewelSiteSetting.customLabelsMap ? data.getJewelSiteSetting.customLabelsMap.customLabels : null)
                settingObj.customLabels = labelsObj
                this.setState({
                    siteSettings: settingObj
                })
                localStorage.setItem('inventory', data.getJewelSiteSetting.inventory)
            }
        }).catch((err) => {
            this.setState({
                loading: false
            })
            console.log(err);
        })
    }
    getMenu = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: getServiceTypes,
                fetchPolicy: 'cache-first',
            })
                .then(({ data }) => {
                    if (data.getServiceTypes) {

                        let menu = data.getServiceTypes
                        let menuArr = []
                        menu && menu.length > 0 && menu.map((m) => {
                            if (m.typeStatus != false && (m.masterServiceType == 'dine-in' || m.slugName == 'dine-in')) {
                                menuArr.push(m)
                            }
                        })

                        this.setState({
                            menuTypes: menuArr,
                        })
                        this.getAllTypeSetting(menuArr)
                    }
                })
                .catch(err => {
                    console.log(`Error : ${JSON.stringify(err)}`)

                })
        })
    }

    getAllTypeSetting = async (allData) => {
        if (allData.length > 0) {
            let obj = {}
            let serviceTypeArr = []
            allData.map((type) => {
                serviceTypeArr.push(type.slugName)
            })
            const settingResp = await getServiceTypeSettingInBulk(serviceTypeArr, this.props.client)
            settingResp && settingResp.length > 0 && settingResp.map((setting) => {
                if (setting && setting.typeValue) {
                    obj[setting.typeValue.split('::')[0]] = setting
                }
            })


            this.setState({ allSettings: obj })
        }
    }

    getAppVersion = () => {
        this.props.client.query({
            query: getRecahoAppVersion
        }).then(({ data }) => {
            if (data.getRecahoAppVersion) {
                this.setAppVersion(data.getRecahoAppVersion)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    delay = ms => new Promise(res => setTimeout(res, ms));
    setAppVersion = async (data) => {
        let staffVersion = localStorage.getItem('staffVersion')
        if (data.staffVersion != staffVersion) {
            message.success("App Updating...", 3)
            localStorage.setItem('staffVersion', data.staffVersion)
            // await this.delay(3000);
            if (window && window.location) {
                // window.location.href = window.location.pathname + '?refresh=true';
                // window.location.reload();
                window.location.replace(window.location.href);
            }
        }
        return data
    }


    registerPushNotif = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                var tok = localStorage.getItem('PNToken')
                if (tok == null) {
                    tok = await getToken()
                    localStorage.setItem('PNToken', tok)
                    let p = await this.tokenUpdate(tok)
                    resolve(p)
                } else {
                    resolve(tok)
                }
            } catch (error) {
                reject(error)
            }
        })
    }

    tokenUpdate = (token) => {
        return new Promise(async (resolve, reject) => {

            this.props.SubscribeTokenToOrderPNAlias({
                variables: {
                    token: token
                }
            }).then((res) => {
                resolve(true)

            }).catch((err) => {
                console.log('token error :', err)
                resolve(false)

            })
        })

    }
    reloadApp = () => {
        if (window && window.location) {
            // window.location.href = window.location.pathname + '?refresh=true';
            // window.location.reload();
            window.location.replace(window.location.href);
        }
    }
    handleSignOut = async () => {
        let flag = confirm("Are you sure you want to Logout??")
        if (flag) {
            await Auth.currentUserInfo()
                .then((userData) => {
                    let Device = this.checkDevice()
                    if (Device == 'Android OS') {
                        let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${userData.username}.deviceKey`)
                        let data = {
                            deviceId: deviceId,
                            siteId: localStorage.getItem('siteId'),
                            username: userData.username
                        }
                        let obj = {
                            "eventName": "LOGOUT",
                            "payload": JSON.stringify(data)
                        }
                        window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                    }

                    if (Device == 'iOS') {
                        let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${userData.username}.deviceKey`)
                        let data = {
                            deviceId: deviceId,
                            siteId: localStorage.getItem('siteId'),
                            username: userData.username
                        }
                        let obj = {
                            "eventName": "LOGOUT",
                            "payload": JSON.stringify(data)
                        }
                        window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
                    }
                })
            localStorage.removeItem('inventory')
            localStorage.removeItem('siteOp')
            localStorage.removeItem("siteId")
            localStorage.removeItem("role")
            document.cookie = 'appVersion=v1; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'version=v1; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            Auth.signOut()
                .then(() => {
                    let origin = window.location.origin

                    if (origin == "https://www.mysnaptrade.com") {
                        window.location = "https://www.mysnaptrade.com/staff/"
                    } else {
                        window.location = window.location.origin;
                    }
                })
                .catch(err => console.log(err));
        } else {
            return
        }
    };

    getPlatformServiceBySlugName = () => {
        this.setState({
            loading: true
        })

        this.props.client.query({
            query: getPlatformServiceBySlugName,
            variables: {
                siteId: localStorage.getItem('siteId'),
                slugServiceName: localStorage.getItem("role") == "WAITER" ? "captain-app" : "chef-app"
            },
            fetchPolicy: 'network-only',

        }).then(({ data }) => {
            if (data && data.getPlatformServiceBySlugName && data.getPlatformServiceBySlugName.status) {
                this.setState({ authorized: true, loading: false })
            } else {
                this.setState({ loading: false, authorized: false })
            }

        }).catch((err) => {
            this.setState({ loading: false })
            console.log(err);
        })
    }


    render() {
        let role = this.state.role
        localStorage.setItem('siteType', this.state.siteType);
        localStorage.setItem('siteOp', this.state.siteOption)

        if ( this.state.loading) {
            return <LoadingComponent />
        }
        if (!this.state.authorized && this.state.loading == false) {
            return <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: this.state.platform == 'mobile' ? "50%" : "10%", maxHeight: "100vh" }}>
                    <div style={{ padding: '10px', fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>

                        <p>Access Denied <Icon type="ExclamationCircleFilled" style={{ color: "red" }} size='sm' /> </p>
                        <p>Please ensure you have the necessary permissions or have purchased the required services to log in to our app.</p>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button type="primary" onClick={this.handleSignOut}>LogOut</Button>
                </div>
            </>
        }


        if (role === "WAITER" || role === "CAPTAIN" || role === "CHEF") {
            return (
                <>
                    {(this.state.loading == true || Object.keys(this.state.siteSettings).length == 0) ? <LoadingComponent /> :
                        <Suspense fallback={<LoadingComponent />}>
                            <SelectCurrencyContext.Provider
                                value={{
                                    curr: this.state.currency,
                                    siteType: this.state.siteType,
                                    siteOption: this.state.siteOption
                                }}
                            >
                                <SettingContext.Provider value={{ settingData: this.state.siteSettings, ...this.state.siteSettings, platform: this.state.platform, userData: this.state.userData, permissions: this.state.permissions, allSettings: this.state.allSettings, categoryData: this.state.categoryData, userRole: role, platformConfig: this.state.platformConfig }}>
                                    {role === undefined && role === 'admin' ?
                                        <Button >LogOut</Button>
                                        : ''}
                                    {role === "WAITER" || role === "CAPTAIN" ?
                                        <WaiterApp {...this.props} />
                                        :
                                        role === "CHEF" ?
                                            <KitchenApp {...this.props} />
                                            :
                                            role === 'Mer_Admin' ?
                                                <KitchenAppStation {...this.props} />
                                                :
                                                <Button onClick={this.reloadApp}>Reload</Button>
                                    }
                                </SettingContext.Provider>
                            </SelectCurrencyContext.Provider>
                        </Suspense>
                    }
                </>
            );
        } else {
            return (
                <>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                        <div>
                            <p style={{ padding: '10px', fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>
                                You are attempting to log in to the Captain App using ADMIN credentials.
                                If you wish to log in as an admin, please visit &nbsp; <a href="https://www.mysnaptrade.com" target="_blank">mysnaptrade.com</a> &nbsp;
                                or download the Recaho Admin app from the Play Store.</p>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button type="primary" onClick={this.handleSignOut}>LogOut</Button>
                    </div>
                </>


            )
        }
    }
}


MainApp.contextType = AuthContext;

const WrapApp = compose(
    // withRouter,
    withApollo,
    graphql(GetSite, {
        options: props => ({
            fetchPolicy: "network-only"
        })
    })

)(MainApp)
export default WrapApp